import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBell } from 'react-icons/fa'; // Import icons
import styles from '../Css/StartCert.module.css'; // Assume you have a CSS file for styling

const StartCert = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { quiz_id, certificate_reference, no_of_question, answer_timeslot_insecond,bookname } = location.state || {};

  return (
    <div className={styles.container}>
      <div className={styles.importantSection}>
        <h2 className={styles.importantText}>
          IMPORTANT <FaBell className={styles.bellIcon} />
        </h2>
        <p>There will be {no_of_question} questions in total.</p>
        <p>Each question will have a {answer_timeslot_insecond} second auto progress timer.</p>
        <p>We recommend you not to switch tabs during the quiz.</p>
        <button
          className={styles.startButton}
          onClick={() => { navigate(`/question`, { state: { quiz_id, certificate_reference, no_of_question, answer_timeslot_insecond,bookname } }); }}
        >
          Let's Go
        </button>
      </div>

      {/* Pass data to the question page using Link's state */}

    </div>
  );
};

export default StartCert;
