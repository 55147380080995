import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../Css/MyCerts.module.css";
import CertificateService from "../Services/CertificateService";
import knowela2024 from "../img/Stamp2024.png";
import knowela2025 from "../img/Stamp2024.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faThumbsUp,
    faCog,
    faCheckCircle,
    faThLarge,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import LoginService from "../Services/LoginService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConstantColors from "../Constants/Colors";
import ConstantStrings from "../Constants/Strings";
import ConstantIntegers from "../Constants/Integers";
import { MyEnum } from "../Constants/Enums";

const MyCerts = () => {
    const bookScrollRef = useRef(null);
    const loginService = LoginService();
    const location = useLocation();
    const navigate = useNavigate();
    const [showWatermark, setShowWatermark] = useState(  process.env.REACT_APP_SHOW_CERTIFICATE_SAMPLE === "true");
    const [result, setResult] = useState(null);
    const [loginuser, setLoginUser] = useState(null);
    const [books, setBooks] = useState([]);
    const [feedbackcount, setFeedbackcount] = useState([]);
    const [selectedBookId, setSelectedBookId] = useState(null);
    const certificateRef1 = useRef();
    const certificateRef2 = useRef();
    const hasFetchedBooks = useRef(false);
    const hasFetchedUser = useRef(false);
    const { certificate_id } = location.state || {};

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
    }); // e.g., "27 February 2024"
    useEffect(() => {
        // if (!hasFetchedResults.current) {
        //     fetchCertificate();
        //     hasFetchedResults.current = true; // Mark as fetched
        // }
        if (!hasFetchedBooks.current) {
            fetchCertifiedBooks();
            hasFetchedBooks.current = true; // Mark as fetched
        }
        if (!hasFetchedUser.current) {
            LoginUser();
            hasFetchedUser.current = true; // Mark as fetched
        }
        const storedFeedback = localStorage.getItem("feedback");
        if (storedFeedback) {
            try {
                setFeedbackcount(JSON.parse(storedFeedback));
            } catch (error) {
                console.error("Failed to parse feedback from localStorage:", error);
            }
        }
    }, []);
    const type20Count = Array.isArray(feedbackcount)
        ? feedbackcount.find((item) => item.type === 20)?.count || 0
        : 0;
    const fetchCertifiedBooks = async () => {
        try {
            const response = await CertificateService.GetCertifiedBooksDetails();
            if (response && response.length > 0) {
                setBooks(response || []);

                // If no certificate_id is provided, select the first book's certificate by default
                if (!certificate_id) {
                    fetchCertificate(response[0].certificate_id);
                     setSelectedBookId(response[0].id)
                } else {
                    fetchCertificate(certificate_id); // Fetch the certificate for the passed certificate_id
                }
            } else {
                toast.error(ConstantStrings.INFO_CERTIFIED_BOOK_NOT_EXIST);
            }
        } catch (error) {
            console.error(
                `${ConstantStrings.ERROR_FETCHING_CERTIFIED_BOOKS}:`,
                error
            );
        }
    };

    const LoginUser = async () => {
        try {
            const response = await loginService.LoggedInUserDetails();
            if (response) {
                setLoginUser(response); // Only set the response if it exists
            }
        } catch (error) {
            console.error("Login failed:", error);
        }
    };
    const fetchCertificate = async (cert_id) => {
        try {
            const response = await CertificateService.GetCertificate(cert_id);
            setResult(response); // Store selected certificate details
        } catch (error) {
            console.error("Error fetching certificate details:", error);
            toast.error("Error fetching certificate details.");
        }
    };
    const GetUserFeedBack = async () => {
        try {
            const response = await CertificateService.GetFeedBack(MyEnum.MyCert);
            localStorage.setItem("feedback", JSON.stringify(response));
            setFeedbackcount(response);
        } catch (error) {
            console.error("Error fetching Feedback:", error);
            toast.error("Error fetching feedback.");
        }
    };

    const scrollBooks = (direction) => {
        const scrollAmount = 300; // You can adjust this value for how much it scrolls
        if (direction === "left") {
            bookScrollRef.current.scrollBy({
                left: -scrollAmount,
                behavior: "smooth",
            });
        } else if (direction === "right") {
            bookScrollRef.current.scrollBy({
                left: scrollAmount,
                behavior: "smooth",
            });
        }
    };
    // const freeCertificate = (certificateRef) => {
    //     setShowWatermark(false);
    //     setTimeout(() => {
    //         html2canvas(certificateRef.current, {
    //             scale: 3
    //         }).then((canvas) => {
    //             const imgData = canvas.toDataURL("image/png");

    //             // Create jsPDF instance with A4 page size
    //             const pdf = new jsPDF({
    //                 orientation: "portrait", // "portrait" or "landscape"
    //                 unit: "mm", // units in mm
    //                 format: "a4", // set A4 format
    //             });

    //             // Get the width and height of the A4 page in mm
    //             const pageWidth = 210;
    //             const pageHeight = 297;

    //             // Calculate the certificate size and positioning
    //             const imgWidth = pageWidth - 60; // Leave some margin (optional)
    //             const imgHeight = (canvas.height * imgWidth) / canvas.width;

    //             // Center the image on the page
    //             const xOffset = (pageWidth - imgWidth) / 2;
    //             const yOffset = (pageHeight - imgHeight) / 2;

    //             // Add image to the PDF
    //             pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

    //             // Save the PDF file with A4 format
    //             pdf.save(`${loginuser.name}-certificate.pdf`);
    //             setShowWatermark(true);
    //         });
    //     }, 100);
    // };
    const PremiumCertificate = (certificateRef) => {
        setShowWatermark(false);
        setTimeout(() => {
            html2canvas(certificateRef.current, {
                scale: 2, // Higher scale for better resolution
                useCORS: true, // Ensures images are captured correctly
            }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                // Create a PDF with A4 dimensions
                const pdf = new jsPDF({
                    orientation: "portrait",
                    unit: "mm",
                    format: "a4",
                });

                // Add the canvas image to the PDF to fill the entire A4 page
                pdf.addImage(imgData, "PNG", 0, 0, 210, 297); // A4 dimensions in mm
                pdf.save(`${loginuser.name}-certificate.pdf`);
                setShowWatermark(process.env.REACT_APP_SHOW_CERTIFICATE_SAMPLE);
            });
        }, 100);
    };

    return (
        <div className={styles.share_container}>
            <div className={styles.topBar}>
                <button
                    className={styles.button}
                    onClick={() => navigate("/dashboard")}
                >
                    <FontAwesomeIcon
                        color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR}
                        icon={faThLarge}
                    />
                    <span>{ConstantStrings.MENU_DASHBOARD_TITLE}</span>
                </button>
                <button className={styles.button} onClick={() => navigate("/mycert")}>
                    <FontAwesomeIcon
                        color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR}
                        icon={faCheckCircle}
                    />
                    <span>{ConstantStrings.MENU_MYCERT_TITLE}</span>
                </button>
                <button className={styles.button} onClick={() => navigate("/setting")}>
                    <FontAwesomeIcon
                        color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR}
                        icon={faCog}
                    />
                    <span>{ConstantStrings.MENU_SETTINGS_TITLE}</span>
                </button>
            </div>
            <div className={styles.books_container}>
                {books.length > 0 ? (
                    <div className={styles.book_scroll}>
                        <button
                            className={styles.scrollButton}
                            onClick={() => scrollBooks("left")}
                        >
                            &#9664; {/* Left arrow symbol */}
                        </button>

                        <div className={styles.bookScrollContainer} ref={bookScrollRef}>
                            <div className={styles.book_card}>
                                <button
                                    className={styles.book_image1} // Use the same CSS class for styling
                                    onClick={() => navigate("/home")} // Navigate to home on click
                                >
                                    <FontAwesomeIcon icon={faPlus} className={styles.plusIcon} />
                                    <br />
                                    <span className={styles.addText}>Add</span>
                                </button>
                            </div>

                            {/* Render the books after the button */}
                            {books.map((book) => (
                                <div
                                    key={book.id} // Ensure "book.id" is unique
                                    className={`${styles.book_card} ${selectedBookId === book.id ? styles.selected : ""
                                        }`}
                                    onClick={() => {
                                        setSelectedBookId(book.id); // Update selected book ID
                                        fetchCertificate(book.certificate_id); // Fetch certificate data
                                    }}
                                >
                                    <img
                                        src={book.thumbnail}
                                        alt={book.name}
                                        className={styles.book_image}
                                    />
                                </div>
                            ))}

                        </div>
                        <button
                            className={styles.scrollButton}
                            onClick={() => scrollBooks("right")}
                        >
                            &#9654; {/* Right arrow symbol */}
                        </button>
                    </div>
                ) : (
                    <div className={styles.book_card}>
                        <button
                            className={styles.book_image1} // Use the same CSS class for styling
                            onClick={() => navigate("/home")} // Navigate to home on click
                        >
                            <FontAwesomeIcon icon={faPlus} className={styles.plusIcon} />
                            <br />
                            <span className={styles.addText}>Add</span>
                        </button>
                    </div>
                )}
            </div>
            {result && (
                <div className={styles.certificate_wrapper}>
                    {/* Free Certificate */}
                    {/* <div>
                        <h3>Free Certificate</h3>
                        <div ref={certificateRef1} className={styles.certificate_card1}>
                            <h3>
                                {result && result.book
                                    ? result.book_name
                                    : "Loading book name..."}
                            </h3>
                            <h1>GRADUATE</h1>
                            <p>CONGRATULATIONS TO:</p>
                            <h3>
                                {loginuser && loginuser.certificate_name ? loginuser.certificate_name : loginuser.name}
                            </h3>
                            <p>
                                For successfully mastering the key concepts from the book "
                                {result && result.book ? result.book_name : "Shoe Dog"}" as
                                validated by the assessment.
                            </p>
                            <h4>
                                Unlock skill <br /> badges{" "}
                            </h4>
                            <div className={styles.logo_container}>
                                <img src={knowela} alt="Logo" />
                            </div>

                            {showWatermark && (
                                <div className={styles.watermark_grid}>
                                    {Array(40)
                                        .fill(0)
                                        .map((_, index) => (
                                            <span key={index} className={styles.fake_text}>
                                                Sample
                                            </span>
                                        ))}
                                </div>
                            )}
                        </div>
                        <button
                            className={styles.download_btn}
                            onClick={() => freeCertificate(certificateRef1)}
                        >
                            Download Free Certificate
                        </button>
                    </div> */}

                    {/* Premium Certificate */}
                    <div>
                        <h3>Premium Certificate</h3>
                        <div ref={certificateRef2} className={styles.certificate_card}>
                            {/* <h3>
                                {result && result.book_name ? result.book_name : "Loading Book name"}
                            </h3> */}
                            <p className={styles.leaders_are_readers}>LEADERS ARE READERS</p>
                            <h1 className={styles.Certificate_of_knowledge}>
                                CERTIFICATE OF KNOWLEDGE
                            </h1>
                            <p className={styles.Congratulation}>CONGRATULATIONS TO:</p>
                            <h3 className={styles.Cert_Username}>
                                {result && result.candidate_name
                                    ? result.candidate_name.slice(0, 35).toUpperCase()
                                    : ""}
                            </h3>
                            <h3 className={styles.two_line_content}>
                                {"For sussessfully mastering the key concepts from the book '"}
                                {result && result.book_name ? result.book_name : ""}
                                {"' as validated by the accessment."}
                            </h3>
                            <h4 className={styles.top_skills}>
                                Top skills & concerns covered:
                            </h4>
                            <div className={styles.Grid_container}>
                                <ul className={styles.skills_grid}>
                                    {result.top_skills != null ? (
                                        result.top_skills
                                            .slice(0, ConstantIntegers.MAXSIZE_CERTIFICATE_SKILLS)
                                            .map((skill, index) => <li key={index}>{skill}</li>)
                                    ) : (
                                        <li></li>
                                    )}
                                </ul>
                            </div>
                            <div className={styles.logo_container}>
                                <img src={knowela2024} alt="Logo" />
                                <p>
                                    Date of achievement:{" "}
                                    {new Date(result.created_date).toLocaleDateString("en-GB", {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                    })}
                                </p>
                                <p>Certificate ID:{result.certificate_reference}</p>
                            </div>
                            {showWatermark && (
                                <div className={styles.watermark_grid}>
                                    {Array(40)
                                        .fill(0)
                                        .map((_, index) => (
                                            <span key={index} className={styles.fake_text}>
                                                Sample
                                            </span>
                                        ))}
                                </div>
                            )}
                        </div>
                        <div className={styles.Cert_button}>
                            <div className={styles.Cert_button_text2}>
                                <p className={styles.Cert_button_text}>USD 12.95</p>
                                <p className={styles.Cert_button_text1}>USD 0.00</p>
                        </div>
                            <button
                                className={styles.download_btn}
                                onClick={() => PremiumCertificate(certificateRef2)}
                            >
                                Download
                            </button>
                        </div>
                        <p className={styles.limited_time_offer}>
                            Exclusive limited-time offer
                        </p>
                    </div>
                </div>
            )}
            <div className={styles.bottom}>
                <h4>Add your other certifications.</h4>
                <p>
                    <strong> Why: </strong> Combine your certifications into one profile.
                    Share the link to showcase your skills.
                </p>
                <h3>This is an upcoming feature.</h3>
                <h3>Should we bring it sooner</h3>

                <FontAwesomeIcon
                    icon={faThumbsUp}
                    onClick={GetUserFeedBack}
                    style={{
                        color: ConstantColors.DASHBOARD_THUMBS_UP_FOREGROUND_COLOR,
                        backgroundColor:
                            ConstantColors.DASHBOARD_THUMBS_UP_BACKGROUND_COLOR,
                        borderRadius: "50px",
                        height: "25px",
                        width: "30px",
                        padding: "10px",
                        marginTop: "10px",
                    }}
                />
                <p style={{ marginTop: "5px", fontSize: "14px", fontWeight: "bold" }}>
                    {type20Count}
                </p>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MyCerts;
