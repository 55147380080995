import React from 'react';
import styles from '../Css/Results.module.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';
const Results = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.results_container}>
            <h1>Thank you for taking the assessment</h1>
            <p>While you didn't pass this time,don't be discouraged </p>
            <p>You can try again with new questions </p>
            <h1>Keep up the great effort</h1>
            <button className={styles.button} onClick={() => navigate('/home')}
            >Got it</button>


        </div>
    );
};

export default Results;
