import axios from "axios";
import { toast } from "react-toastify"; // Assuming you are using react-toastify for toast notifications
import ConstantStrings from "../Constants/Strings";

// Create an axios instance for API calls
const apiService = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});
// Add a request interceptor to include token in headers
apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set Authorization header
    } else {
      // TODO - Redirect to login page if no token exists
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle errors globally
apiService.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      // Check if the response data is null or empty
      if (!response.data || (Array.isArray(response.data) && response.data.length === 0 && 
      !response.request.responseURL.includes("api/books/favouritebooks"))) 
      {
        const error = new Error(ConstantStrings.INFO_NO_DATA_EXIST);
        error.cause = "info";
        return Promise.reject(error);
      }
    }
    return response; // Just return the response if no error
  },
  (error) => {
    if (error.response) {
      // Server responded with a status code other than 2xx
      if (error.response.status === 401) {
        setTimeout(() => {
          redirectToLogin(); // Redirect after a delay
        }, 2000);// Call the redirect function to login page
      } else if (error.response.status === 402) {
        toast.error(ConstantStrings.INFO_UNAUTHORIZE_ATTEMPT); // Display error toast
      } else if (error.response.status === 400) {
        toast.error(ConstantStrings.ERROR_OCCURED);
      } else if (error.response.status === 500) {
        toast.error(ConstantStrings.ERROR_INTERNAL_SERVER);
      } else {
        toast.error(ConstantStrings.ERROR_OCCURED);
      }
    } else if (error.request) {
      // Request was made but no response was received
      toast.error(ConstantStrings.ERROR_TIMEOUT);
    } else {
      // Something else happened in making the request
      toast.error(ConstantStrings.ERROR_OCCURED);
    }
    return Promise.reject(error); // Rethrow the error
  }
);
const redirectToLogin = () => {
  window.location.href = "/";
  localStorage.removeItem("token"); // Redirects to the login page
};

export default apiService;
