export default{

    BOOK_WILD_SEARCH_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/books?WildSearch=<param1>&FavouriteBooks=<param2>`,
    START_CERTIFICTION_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/quiz/start/<param1>`,
    COURSE_SUMMARY_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/books/coursesummary?bookId=<param1>`,
    CERTIFICATE_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/certification/<param1>`,
    CERTIFICATE_BY_QUIZID_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/certification?quizId=<param1>`,
    CERTIFIED_BOOKS_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/certification/certifiedbooks`,
    QUIZ_QUESTIONS_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/quiz/question?QuizId=<param1>&PageNumber=<param2>&PageSize=1`,
    ANSWER_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/quiz/answer`,
    LOGGED_IN_USER_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/users/loggedinuser`,
    LOGIN_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/login`,
    USERPROFILE_LINK: `${process.env.REACT_APP_CERT_UTILITY_API}/api/userprofile`,
    FAVOURITE_BOOKS_LINK:`${process.env.REACT_APP_CERT_UTILITY_API}/api/books/favouritebooks/<param1>`,
    USER_FEEDBACK_LINK:`${process.env.REACT_APP_CERT_UTILITY_API}/api/feedback`,

}