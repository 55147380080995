import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import styles from "../Css/Login.module.css";
import { useNavigate } from "react-router-dom";
import LoginService from "../Services/LoginService";
import ConstantStrings from "../Constants/Strings";

const LoginPage = ({ onLoginSuccess }) => {
  const loginService = LoginService();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading]= useState(false);
  const navigate = useNavigate();
  const handleSuccess = async (credentialResponse) => {
    setLoading(true);
    try {
      const response = await loginService.Login(null, credentialResponse.credential,ConstantStrings.GOOGLE_LOGIN_PROVIDER,);
      onLoginSuccess(response.token);
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    } catch (error) {
      toast.error(ConstantStrings.ERROR_LOGIN_FAILED_TRY_AGAIN); 
    }
    setLoading(false);
  }; 
  const handleLogin = async () => {
    setLoading(true);
    if (!email) {
      toast.error(ConstantStrings.INFO_LOGIN_EMAIL_MANDATORY); 
      return;
    } 
    if (!password) {
      toast.error(ConstantStrings.INFO_LOGIN_EMAIL_MANDATORY); 
      return;
    }
    try {
      const response = await loginService.Login(email, password,ConstantStrings.KNOWELA_LOGIN_PROVIDER);
      onLoginSuccess(response.token);
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    } catch (error) {
      toast.error(ConstantStrings.ERROR_LOGIN_FAILED_TRY_AGAIN); 
    }
    setLoading(false);
  };


  return (
    <div className={styles.loginContainer}>
           {loading ? ( // Display loading indicator if data is being fetched
        <div className={styles.loadingContainer}>
          <div className={styles.loader}></div>
        </div>
      ) : (
      <div className={styles.googleButton}>
        <h1>Knowela</h1>
        <h2 className={styles.heading}>Sign In</h2>
        <input
          type="email"
          placeholder={ConstantStrings.PLACEHOLDER_LOGIN_EMAIL}
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Update email state on input change
          className={styles.inputField} // Add your own CSS class
        />
        <br />
        <input
          type="password"
          placeholder= {ConstantStrings.PLACEHOLDER_LOGIN_PASSWORD}
          value={password}
          onChange={(e) => setPassword(e.target.value)} // Update email state on input change
          className={styles.inputField} // Add your own CSS class
        />
        <br></br>
        <button onClick={handleLogin} className={styles.continueButton}>Continue</button>
        <div className={styles.customGoogleButton}>
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={() => {
              toast.error(ConstantStrings.ERROR_LOGIN_FAILED_TRY_AGAIN); 
            }}
          // Apply CSS class to the button
          />
        </div>
      </div> )}
      <ToastContainer /> 
    </div>
  );
};

export default LoginPage;
