import React from 'react'

export default function Profile() {
    return (
        <div style={{
            display: "flex", justifyContent: "center", fontSize: "30px",
            fontWeight:"bold"


        }}>Phase 2 functionality</div>
    )
}
