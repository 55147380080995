import apiService from "./ApiService";
import ConstantStrings from "../Constants/Strings";
import ConstantApis from "../Constants/Api";

const LoginService = () => {
  return {
    // Send user_id, provider, and token in the POST request body
    Login: async (user_id,token_, provider) => {
      try {
        const data = {
          user_id: user_id,
          provider: provider,
          token: token_
        };

        const response = await apiService.post(ConstantApis.LOGIN_LINK, data);

        return response.data; // Return the data from the response
      } catch (error) {
        console.error(ConstantStrings.ERROR_OCCURED, error);
        throw error; // Rethrow the error to be handled by the caller
      }
    },
    LoggedInUserDetails: async () => {
  
        const res = await apiService.get(ConstantApis.LOGGED_IN_USER_LINK);
        return res.data; // Proceed if status is 200
    
    },
  };
};

export default LoginService;
