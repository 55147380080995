import apiService from "./ApiService";
import ConstantStrings from "../Constants/Strings";
import ConstantApis from "../Constants/Api";

const ProfileService = {
  UserProfile: async () => {
    try {
      const response = await apiService.get(ConstantApis.USERPROFILE_LINK);
      return response.data;
    } catch (error) {
      console.error(ConstantStrings.ERROR_FETCHING_USER_PROFILE, error);
      throw error; // Rethrow the error for handling it in the component if necessary
    }
  },
  UpdatedUserProfile: async (certificate_name,profile_id) => {
    try {
      // Get the API URL from the endpoint
      const apiUrl = ConstantApis.USERPROFILE_LINK;
      const data = {
        certificate_name:certificate_name,
        profile_id:profile_id
      };
      const response = await apiService.put(apiUrl, data);
      return response.data; // Return the data from the response
    } catch (error) {
      console.error(ConstantStrings.ERROR_UPDATING_USER_PROFILE, error);
      throw error; // Rethrow the error to be handled by the caller
    }
  },
};

export default ProfileService;
