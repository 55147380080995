export default {
    ERROR_FETCHING_BOOKS: 'Error fetching books',
    ERROR_FETCHING_BOOK_DETAILS: 'Error fetching book details',
    ERROR_DURING_CERTIFICATION: 'Error during certification',
    ERROR_LOGIN_FAILED_TRY_AGAIN: 'Login failed. Please try again.',
    ERROR_FETCHING_PROFILE: 'Error fetching profile',
    ERROR_PROFILE_ID_IS_NULL: 'Profile ID cannot be empty',
    ERROR_FETCHING_USER_PROFILE: 'Error fetching user profile',
    ERROR_UPDATING_USER_PROFILE: 'Error updating user profile',
    ERROR_FETCHING_CERTIFIED_BOOKS: 'Error fetching certified books',
    ERROR_FETCHING_CERTIFICATE: 'Error fetching certificate',
    ERROR_SUBMITING_ANSWER: 'Error submitting answer or fetching next question',
    ERROR_FETCHING_QUESTION: 'Error fetching question',
    ERROR_FETCHING_CERTIFICATE_DETAIL: 'Error fetching certificate details',
    ERROR_OCCURED: 'Error occured',
    ERROR_INTERNAL_SERVER: 'An internal server error occurred. Please try again later.',
    ERROR_TIMEOUT: 'No response from the server. Please check your network connection.',
    ERROR_FETCHING_QUESTION_ANSWER: 'Error fetching answer',
    ERROR_FETCHING_FAVOURITE_BOOKS: 'Error fetching favourite books',
    ERROR_FETCHING_FEEDBACK: `Error in fetching feedback`,


    INFO_LOGIN_EMAIL_MANDATORY: 'Please enter your email.',
    INFO_PROFILE_UPDATED_SUCCESSFULY: 'Profile successfuly updated',
    INFO_CERTIFIED_BOOK_NOT_EXIST: 'Certified books not exists',
    INFO_LOGIN_FAILED: 'Login failed',
    INFO_NO_DATA_EXIST: 'Requested data does not exist',
    INFO_UNAUTHORIZE_ATTEMPT: 'Unauthorized access. Redirecting to Login.',

    PLACEHOLDER_SEARCH_BOOK: 'Search for any book or author',
    PLACEHOLDER_LOGIN_EMAIL: 'Email',
    PLACEHOLDER_LOGIN_PASSWORD: 'Password',
    PLACEHOLDER_CERTIFICATE_NAME: 'Enter the name you want on your certificates',

    MENU_DASHBOARD_TITLE: 'Dashboard',
    MENU_MYCERT_TITLE: 'MyCerts',
    MENU_SETTINGS_TITLE: 'Settings',

    GOOGLE_LOGIN_PROVIDER: 'Google',
    KNOWELA_LOGIN_PROVIDER: 'Knowela',
}