import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faThLarge,
    faCheckCircle,
    faCog,
    faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import styles from "../Css/Dashboard.module.css"; // Assuming you're using CSS modules
import { useNavigate } from "react-router-dom";
import dashboard_img from "../img/skills_wheel.png"
import ConstantColors from "../Constants/Colors";
import ConstantStrings from "../Constants/Strings";
import { MyEnum } from "../Constants/Enums";
import CertificateService from "../Services/CertificateService";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [feedbackcount, setFeedbackcount] = useState([]);

    useEffect(() => {
        const storedFeedback = localStorage.getItem("feedback");
        if (storedFeedback) {
            try {
                setFeedbackcount(JSON.parse(storedFeedback));
            } catch (error) {
                console.error("Failed to parse feedback from localStorage:", error);
            }
        }
    }, []);
    const type20Count = Array.isArray(feedbackcount)
        ? feedbackcount.find((item) => item.type === 10)?.count || 0
        : 0;
    const GetUserFeedBack = async () => {
        try {
            const response = await CertificateService.GetFeedBack(MyEnum.Dashboard);
            localStorage.setItem("feedback", JSON.stringify(response));
            setFeedbackcount(response);
        } catch (error) {
            console.error("Error fetching Feedback:", error);
            toast.error("Error fetching feedback.");
        }
    };
    return (
        <div className={styles.container}>
            {/* Top Bar with Icons */}
            <div className={styles.topBar}>
                <button
                    className={`${styles.button} ${location.pathname === "/dashboard" ? styles.active : ""
                        }`}
                    onClick={() => navigate("/dashboard")}
                >
                    <FontAwesomeIcon
                        color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR}
                        icon={faThLarge}
                    />
                    <span>{ConstantStrings.MENU_DASHBOARD_TITLE}</span>
                </button>
                <button
                    className={`${styles.button} ${location.pathname === "/mycert" ? styles.active : ""
                        }`}
                    onClick={() => navigate("/mycert")}
                >
                    <FontAwesomeIcon
                        color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR}
                        icon={faCheckCircle}
                    />
                    <span>{ConstantStrings.MENU_MYCERT_TITLE}</span>
                </button>
                <button
                    className={`${styles.button} ${location.pathname === "/setting" ? styles.active : ""
                        }`}
                    onClick={() => navigate("/setting")}
                >
                    <FontAwesomeIcon
                        color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR}
                        icon={faCog}
                    />
                    <span>{ConstantStrings.MENU_SETTINGS_TITLE}</span>
                </button>
            </div>
            <div className={styles.content}>
                <h4>Your Skills Dashboard (Sample)</h4>
                <p>It would be visible publicly.</p>
                <img
                    src={dashboard_img}
                    alt={"Dashboard_img"}
                    className={styles.image}
                />
                <div className={styles.bottom}>
                    <h3>This is an upcoming feature.</h3>
                    <h3>Should we bring it sooner</h3>
                    <FontAwesomeIcon
                        icon={faThumbsUp}
                        onClick={GetUserFeedBack}
                        style={{
                            color: ConstantColors.DASHBOARD_THUMBS_UP_FOREGROUND_COLOR,
                            backgroundColor: ConstantColors.DASHBOARD_THUMBS_UP_BACKGROUND_COLOR,
                            borderRadius: "50px",
                            height: "25px",
                            width: "30px",
                            padding: "10px",
                            marginTop: "10px"
                        }}
                    />
                    <p style={{ marginTop: "5px", fontSize: "14px", fontWeight: "bold" }}>
                        {type20Count}
                    </p>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Dashboard;
