import React,{useState,useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faCheckCircle,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../Css/WhyCertify.module.css"; // Assuming you're using CSS modules
import { useNavigate } from "react-router-dom";
import ConstantColors from "../Constants/Colors";
import ConstantStrings from "../Constants/Strings";

const WhyCertify = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null); // Track token instead of isLoggedIn
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken); // Set token if it exists in localStorage
    }
  }, []);
  return (
    <div className={styles.container}>
      {/* Top Bar with Icons */}
      {token && (
      <div className={styles.topBar}>
                <button className={styles.button}
                    onClick={() => navigate("/dashboard")}>
                    <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faThLarge} />
                    <span>{ConstantStrings.MENU_DASHBOARD_TITLE}</span>
                </button>
                <button className={styles.button}
                    onClick={() => navigate("/mycert")}>
                    <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faCheckCircle} />
                    <span>{ConstantStrings.MENU_MYCERT_TITLE}</span>
                </button>
                <button
                    className={styles.button}
                    onClick={() => navigate("/setting")}
                >
                    <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faCog} />
                    <span>{ConstantStrings.MENU_SETTINGS_TITLE}</span>
                </button>
        </div>
        )}

      {/* Content Section */}
      <div className={styles.content}>
        <h1>1. What is Knowela and Book Certifications All About?</h1>
        <p>
          Knowela empowers you to extract the hidden value from your reading. Go
          beyond basic concepts and gain practical skills, valuable ideas, and
          fresh perspectives from the books you choose. Earn certifications that
          validate your understanding and showcase your intellectual investment.
        </p>

        <h2>2. Why Should I Get Certified for Books I've Read?</h2>
        <p>
          Many books equip you with valuable skills that often remain
          unrecognized. Knowela certifications change that. Prove your
          comprehension, solidify your learning, and unlock new possibilities.
          Certifications showcase your knowledge acquisition, boost your memory
          of key skills and ideas, and guide you towards deeper expertise in
          areas that interest you.
        </p>

        <h2>3. What is the Process to Get Certified?</h2>
        <p>
          Simple and rewarding: Dive deep into a book you love, then test your
          understanding with our expert-designed quiz. Earn a personalized
          certificate showcasing your achievement and the valuable skills and
          knowledge you've gained.
        </p>

        <h2>4. What Else Do I Get from Knowela?</h2>
        <p>
          Knowela equips you with a public skills dashboard. Share this link
          anywhere to showcase the skills and knowledge you've gained from
          reading. You also get personalized recommendations for new books to
          expand your skillset. Our in-depth content analysis unlocks deeper
          understanding of the book's ideas, and while we're still under
          development, a thriving community is coming soon for future engagement
          and knowledge sharing.
        </p>

        <h2>5. What Can I Do with Knowela?</h2>
        <p>
          Spotlight your skills and knowledge on your resume. Certifications
          showcase your commitment to continuous learning, building a powerful
          personal brand and putting your book-learned skills in the spotlight
          for career advancement. Track your learning progress with our skills
          dashboard and see your knowledge base grow.
        </p>

        <h2>6. What If I Don't Pass the Quiz?</h2>
        <p>
          No worries! Retake it with our insightful feedback. Each attempt
          strengthens your learning and helps you master the book's content,
          solidifying both the concepts and the valuable skills it offers.
        </p>

        <h2>7. Is Knowela Worth the Investment?</h2>
        <p>
          Absolutely! Free and premium options cater to your needs. Invest in
          yourself and your knowledge base. Certifications never expire, so your
          learning and skill development are yours to keep.
        </p>
      </div>
    </div>
  );
};

export default WhyCertify;
