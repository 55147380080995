import apiService from "./ApiService";
import ConstantStrings from "../Constants/Strings";
import ConstantApis from "../Constants/Api";

const QuestionService = {
  Question: async (quiz_id, pageNumber) => {
    try {
      const response = await apiService.get(ConstantApis.QUIZ_QUESTIONS_LINK.replace('<param1>',quiz_id).replace('<param2>', pageNumber));
      return response;
    } catch (error) {
      console.error(ConstantStrings.ERROR_FETCHING_BOOK_DETAILS, error);
      throw error; // Rethrow the error for handling it in the component if necessary
    }
  },
  GetAnswers: async (quiz_id, question_id, answer_id, next_page_number) => {
    try {
      // Get the API URL from the endpoint
      const apiUrl = ConstantApis.ANSWER_LINK;
      const data = {
        quiz_id: quiz_id,
        question_id: question_id,
        answer_id: answer_id,
        next_page_number: next_page_number,
      };
      const response = await apiService.post(apiUrl, data);
      return response.data; // Return the data from the response
    } catch (error) {
      console.error(ConstantStrings.ERROR_FETCHING_QUESTION_ANSWER, error);
      throw error; // Rethrow the error to be handled by the caller
    }
  },
  StartCert: async (id) => {
    const response = await apiService.get(ConstantApis.START_CERTIFICTION_LINK.replace('<param1>',id));
    return response.data;
  },
  GetCertificateByQuiz: async (quiz_id) => {
    const response = await apiService.get(ConstantApis.CERTIFICATE_BY_QUIZID_LINK.replace('<param1>',quiz_id));
    return response.data;
  },
};

export default QuestionService;
