import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faCheckCircle,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../Css/About.module.css"; // Assuming you're using CSS modules
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigation = useNavigate();
  const [token, setToken] = useState(null); // Track token instead of isLoggedIn
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken); // Set token if it exists in localStorage
    }
  }, []);
  return (
    <div className={styles.container}>
      {/* Top Bar with Icons */}
      {token && (
        <div className={styles.topBar}>
          <button className={styles.button}
            onClick={() => navigation("/dashboard")}>
            <FontAwesomeIcon color="green" icon={faThLarge} />
            <span>Dashboard</span>
          </button>
          <button className={styles.button}
            onClick={() => navigation("/mycert")}>
            <FontAwesomeIcon color="green" icon={faCheckCircle} />
            <span>MyCerts</span>
          </button>
          <button
            className={styles.button}
            onClick={() => navigation("/setting")}
          >
            <FontAwesomeIcon color="green" icon={faCog} />
            <span>Settings</span>
          </button>
        </div>)}

      {/* Content Section */}
      <div className={styles.content}>
        <p className={styles.content_p}>
          From Social Validation to Unveiling Hidden Knowledge: <strong>The Knowela
            Story</strong>
        </p>
        <p>
          We've all seen it - the endless stream of achievements shared online,
          from travel photos to certifications and promotions. While there's no
          denying the satisfaction of accomplishment, the underlying psychology
          is often a complex mix of validation, recognition, and even a touch of
          FOMO (fear of missing out).
        </p>
        <p>
          This trend extends to online learning platforms like Udemy and EdX,
          where people invest in certifications, often following free
          introductory courses. At prestigious institutions like Harvard or
          Cambridge Online, the learning experience comes with a premium price
          tag. Yet, what happens after acquiring a certificate? A quick social
          media post announcing the achievement becomes the norm.
        </p>
        <p>
          But what about books? Leaders are renowned readers, and reading
          undeniably broadens knowledge, shapes perspectives, and enhances
          personal growth. Yet, unlike online courses, there's often no tangible
          way to showcase this valuable pursuit.
        </p>
        <p>
          This is where Knowela was born. We saw a gap in the system for readers
          who craved recognition for their intellectual investment. We
          envisioned a way to transform book learning into something
          demonstrable - a way to showcase not just completion, but the valuable
          skills and knowledge gained.
        </p>
        <p>
          Knowela is more than just certification; it's about unlocking the
          hidden potential within your reading experience. We want your
          knowledge to be recognized, not just by you, but by the world.
        </p>
      </div>
    </div>
  );
};

export default About;
