import apiService from "./ApiService";
import defaultThumbnail from "../img/image_not_found1.jpg"; // Import the fallback image
import ConstantApis from "../Constants/Api";


const BooksService = {
  FetchBooks: async (searchQuery, isfavourite) => {
    const response = await apiService.get(ConstantApis.BOOK_WILD_SEARCH_LINK.replaceAll('<param1>',searchQuery).replaceAll('<param2>',isfavourite));
    const books = response.data.map((book) => {
      // Check for thumbnail, and use default if necessary
      return {
        ...book,
        thumbnail: book.thumbnail || defaultThumbnail, // Use default image if thumbnail is missing
      };
    });
    return books;
  },
};

export default BooksService;
