import React, { useState, useEffect, useRef } from "react";
import styles from "../Css/Books.module.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginService from "../Services/LoginService";
import Knowela_landing_page from '../img/knowela_landing_page.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ConstantStrings from "../Constants/Strings";
import { GoogleLogin } from "@react-oauth/google";
import { toast, ToastContainer } from "react-toastify";
import CertificateService from "../Services/CertificateService";
import BooksService from "../Services/BooksService";

const Books = ({ onLoginSuccess }) => {
  const loginService = LoginService();
  const [searchQuery, setSearchQuery] = useState("");
  const hasFetchedUser = useRef(false);
  const [loginuser, setLoginUser] = useState(null);
  const [booksData, setBooksData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token") || null); // Initialize token from localStorage
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    if (token && !hasFetchedUser.current) {
      LoginUser();
      GetUserFeedback();
      hasFetchedUser.current = true;
    }
  }, [token]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission
      handleFetchBooks(false); // Fetch books based on search query
    }
  };

  const handleFetchBooks = async (isRecommendation = false) => {
    setIsFetching(true);
    try {
      const fetch = await BooksService.FetchBooks(
        isRecommendation ? "" : searchQuery,
        isRecommendation
      );
      const key = searchQuery ? {"query":`${searchQuery}`} : "all"; // Add a key to indicate the source
      setBooksData(fetch || []);
      navigate("/booklist", { state: { booksData: fetch, sourceKey: key } }); // Pass the key to the next screen
    } catch (error) {
      if (error.cause === "info") {
        toast.info(error.message);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsFetching(false);
    }
  };
  

  const GetUserFeedback = async () => {
    try {
      const response = await CertificateService.ShowFeedBack();
      if (response) {
        // Convert the JSON response to a string before storing it in localStorage
        localStorage.setItem("feedback", JSON.stringify(response));
      }
    } catch (error) {
      console.error(`${ConstantStrings.ERROR_FETCHING_FEEDBACK}:`, error);
    }
  };

  const handleSuccess = async (credentialResponse) => {
    setLoading(true);
    try {
      const response = await loginService.Login(
        null,
        credentialResponse.credential,
        ConstantStrings.GOOGLE_LOGIN_PROVIDER
      );
      const userToken = response.token;
      onLoginSuccess(userToken);
      localStorage.setItem("token", userToken); // Store token in localStorage
      setToken(userToken);
      window.location.reload(); // Refresh the page after successful login
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    } catch (error) {
      toast.error(ConstantStrings.ERROR_LOGIN_FAILED_TRY_AGAIN);
    }
    setLoading(false);
  };

  const LoginUser = async () => {
    try {
      const response = await loginService.LoggedInUserDetails();
      if (response) {
        setLoginUser(response); // Only set the response if it exists
      }
    } catch (error) {
      console.error(`${ConstantStrings.ERROR_LOGIN_FAILED_TRY_AGAIN}:`, error);
    }
  };

  return (
    <div className={styles.app_container}>
      {isFetching && (
        <div className={styles.loadingContainer}>
          <div className={styles.loader}></div> {/* Loader styles */}
        </div>
      )}
      <div className={styles.con1}>
        <div className={styles.text_column}>
          <h1>{loginuser && loginuser.name ? loginuser.name : ""}</h1>
          <div className={styles.margin}>
            <p className={styles.text}>
              Get <span className={styles.green}>certified</span> on the <strong>BOOKS</strong> you have <strong className={styles.green}>READ</strong>
            </p>
          </div>
        </div>

        {/* Conditional rendering based on token */}
        {token ? (
          <>
            {/* Search Bar and Recommendation Link */}
            <div className={styles.search_bar_container}>
              <div className={styles.input_with_icon}>
                <FontAwesomeIcon icon={faSearch} className={styles.icon} />
                <input
                  type="text"
                  placeholder={ConstantStrings.PLACEHOLDER_SEARCH_BOOK}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleKeyPress} // Handle Enter key press
                />
              </div>
            </div>
            <button
              onClick={() => handleFetchBooks(false)}
              className={styles.recommendations_link}>
              or see what others are getting certified on
            </button>
          </>
        ) : (
          /* Google Login Button */
          <div className={styles.customGoogleButton}>
            <GoogleLogin
              width={300}
              shape="circle"
              theme="filled_black"
              type="standard"
              onSuccess={handleSuccess}
              onError={() => {
                toast.error(ConstantStrings.ERROR_LOGIN_FAILED_TRY_AGAIN);
              }}
            />
          </div>
        )}
      </div>

      <div className={styles.con2}>
        <img src={Knowela_landing_page} alt="Knowela" className={styles.image} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Books;
