import apiService from "./ApiService";
import defaultThumbnail from "../img/image_not_found1.jpg"; // Import the fallback image
import ConstantStrings from "../Constants/Strings";
import ConstantApis from "../Constants/Api";

const CertificateService = {
  // Fetch the results for a specific certificate
  GetCertificate: async (certificate_id) => {
    try {
      const options = await apiService.get(ConstantApis.CERTIFICATE_LINK.replace('<param1>',certificate_id));
      return options.data; // Proceed if status is 200
    } catch (error) {
      console.error(ConstantStrings.ERROR_FETCHING_CERTIFICATE, error);
      throw error;  // Rethrow the error so it can be handled by the caller if needed
    }
  },

  // Fetch details of certified books
  GetCertifiedBooksDetails: async () => {
    try {
      const options = await apiService.get(ConstantApis.CERTIFIED_BOOKS_LINK);
      const books = options.data.map((book) => {
        // Check for thumbnail, and use default if necessary
        return {
          ...book,
          thumbnail: book.thumbnail || defaultThumbnail, // Use default image if thumbnail is missing
        };
      });
      return books;
    } catch (error) {
      console.error(ConstantStrings.ERROR_FETCHING_CERTIFIED_BOOKS, error);
      throw error;  // Rethrow the error so it can be handled by the caller if needed
    }
  },
  GetFeedBack: async (number) => {
    try {
      const apiUrl = ConstantApis.USER_FEEDBACK_LINK;
      const data = {
        type:number
      };
      const response = await apiService.post(apiUrl, data);
      return response.data; // Return the data from the response // Proceed if status is 200
    } catch (error) {
      console.error(ConstantStrings.ERROR_FETCHING_CERTIFICATE, error);
      throw error;  // Rethrow the error so it can be handled by the caller if needed
    }
  },
  ShowFeedBack: async () => {
    try {
      const apiUrl = ConstantApis.USER_FEEDBACK_LINK;
      const response = await apiService.get(apiUrl);
      return response.data; // Return the data from the response // Proceed if status is 200
    } catch (error) {
      console.error(ConstantStrings.ERROR_FETCHING_CERTIFICATE, error);
      throw error;  // Rethrow the error so it can be handled by the caller if needed
    }
  },
};

export default CertificateService;
