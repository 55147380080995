
import defaultThumbnail from "../img/image_not_found1.jpg"; // Import the fallback image
import apiService from "./ApiService";
import ConstantStrings from "../Constants/Strings";
import ConstantApis from "../Constants/Api";

const BookListService = {
  
  FetchBookDetails: async (bookId) => {
    try {
      const res = await apiService.get(ConstantApis.COURSE_SUMMARY_LINK.replace('<param1>',bookId));

      const bookDetails = {
        ...res.data,
        book: {
          ...res.data.book,
          thumbnail: res.data.book.thumbnail || defaultThumbnail, // Use default image if thumbnail is missing
        }
      };
      return bookDetails;
    } catch (error) {
      console.error(ConstantStrings.ERROR_FETCHING_BOOKS, error);
      throw error; // Rethrow the error for handling it in the component if necessary
    }
  },
  AddFavouriteBooks: async (bookid) => {
    const response = await apiService.post(ConstantApis.FAVOURITE_BOOKS_LINK.replaceAll('<param1>',bookid));
    return response.data;
  },
  DeleteFavouriteBooks: async (bookid) => {
    const response = await apiService.delete(ConstantApis.FAVOURITE_BOOKS_LINK.replaceAll('<param1>',bookid));
    return response.data;
  },
};

export default BookListService;
