import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faCheckCircle,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../Css/Method.module.css"; // Update CSS module path if needed
import { useNavigate } from "react-router-dom";
import ConstantColors from "../Constants/Colors";
import ConstantStrings from "../Constants/Strings";
const Method = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null); // Track token instead of isLoggedIn
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken); // Set token if it exists in localStorage
    }
  }, []);
  return (
    <div className={styles.container}>
      {/* Top Bar with Icons */}
      {token && (
        <div className={styles.topBar}>
          <button className={styles.button}
            onClick={() => navigate("/dashboard")}>
            <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faThLarge} />
            <span>{ConstantStrings.MENU_DASHBOARD_TITLE}</span>
          </button>
          <button className={styles.button}
            onClick={() => navigate("/mycert")}>
            <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faCheckCircle} />
            <span>{ConstantStrings.MENU_MYCERT_TITLE}</span>
          </button>
          <button
            className={styles.button}
            onClick={() => navigate("/setting")}
          >
            <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faCog} />
            <span>{ConstantStrings.MENU_SETTINGS_TITLE}</span>
          </button>
        </div>)}

      {/* Content Section */}
      <div className={styles.content}>
        <h2>Knowela Empowers Your Reading Journey</h2>
        <p>
          Knowela empowers you to transform your reading experience into a
          journey of skill development. Our certification process leverages a
          data-driven approach, inspired by established frameworks like Bloom's
          Taxonomy (cognitive skills assessment) and industry standards such as
          O*NET OnLine (Skills & Knowledge) and Skills Framework for the
          Information Age (SFIA) (broad skills categories).
        </p>
        <p>Here's how it works:</p>
        <ul>
          <li>
            <strong>Expert-Backed Quizzes:</strong> We leverage the power of
            AI-powered quiz generation using our proprietary algorithms. This
            advanced approach is further bolstered by the knowledge of
            subject-matter experts. This ensures a comprehensive assessment that
            delves deeper than simple recall, aligning with Bloom's Taxonomy's
            various cognitive levels.
          </li>
          <li>
            <strong>Knowela Skills Taxonomy:</strong> Our unique taxonomy goes
            beyond basic reporting. It leverages insights from O*NET OnLine and
            SFIA to categorize your knowledge across key areas like
            communication and critical thinking, creating a personalized skills
            map.
          </li>
          <li>
            <strong>Actionable Insights & Recommendations:</strong> Don't just
            see your results – understand them. We provide clear visualizations
            and recommend personalized learning resources (books, articles,
            courses) to help you capitalize on your strengths and address skill
            gaps.
          </li>
        </ul>
        <h3>Knowela's certification process empowers you to:</h3>
        <ul>
          <li>
            Validate Your Learning: Gain a recognized certification that
            showcases your comprehension of a book's content.
          </li>
          <li>
            Identify Skill Strengths: Discover your strongest areas and build
            upon them.
          </li>
          <li>
            Uncover Skill Gaps: Unearth areas where you can further develop your
            knowledge.
          </li>
          <li>
            Unlock Learning Potential: Utilize personalized recommendations to
            continue your learning journey.
          </li>
        </ul>
        <p>
          With Knowela, reading becomes an investment in your growth. Experience
          the power of transforming knowledge into actionable skills, and unlock
          your full potential.
        </p>
      </div>
    </div>
  );
};

export default Method;
