import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../Css/BookList.module.css";
import BookListService from "../Services/BookListService";
import QuestionService from "../Services/QuestionService";
import BooksService from "../Services/BooksService";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConstantStrings from "../Constants/Strings";
import ConstantIntegers from "../Constants/Integers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faCheckSquare, faV } from "@fortawesome/free-solid-svg-icons";

const BookList = () => {
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [bookDetails, setBookDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [modalLoading, setModalLoading] = useState(false); // Loading state for modal
  const navigate = useNavigate();
  const location = useLocation();
  const [Latest_gpt_response_id, setLatest_gpt_response_id] = useState(null);
  const [bookname, setBookName] = useState(null);
  const { booksData, sourceKey } = location.state || {};
  const [activeButton, setActiveButton] = useState(null);
  useEffect(() => {
    if (sourceKey === "all") {
      setActiveButton("all"); // "All" is active by default when sourceKey is "all"
    } else {
      setActiveButton(null); // Both buttons are inactive when sourceKey is not "all"
    }
    setBooks(booksData);
    setLoading(false);

  }, [sourceKey]);
  const fetchBooks = async (query, favouritebooks) => {
    setLoading(true); // Start loading when the API call begins
    try {
      const fetch = await BooksService.FetchBooks(query, favouritebooks);
      setBooks(fetch || []);
    } catch (error) {
      setBooks([]); // Handle error by setting books to an empty array
    } finally {
      setLoading(false); // Stop loading once the API call finishes
    }
  };
  const fetchBookDetails = async (bookId) => {
    setModalLoading(true); // Start modal loading when fetching details
    try {
      const response = await BookListService.FetchBookDetails(bookId);
      setBookDetails(response);
      setLatest_gpt_response_id(response.latest_gpt_response_id);
      setBookName(response.book.name);
    } catch (error) {
      console.error(`${ConstantStrings.ERROR_FETCHING_BOOK_DETAILS}:`, error);
    } finally {
      setModalLoading(false); // Stop modal loading once the API call finishes
    }
  };

  const handleFavoriteToggle = async (book) => {
    try {
      if (book.is_favourite) {
        await BookListService.DeleteFavouriteBooks(book.id);
        book.is_favourite = false;
      } else {
        await BookListService.AddFavouriteBooks(book.id);
        book.is_favourite = true;
      }
      setBooks([...books]);
      //console.log([...books]);
    } catch (error) {
      console.error(`${ConstantStrings.ERROR_FETCHING_FAVOURITE_BOOKS}`, error);
    }
  };


  const openModal = async (book) => {
    if (book.certificate_id) {
      navigate(`/mycert`, {
        state: {
          certificate_id: book.certificate_id,
        },
      });
    }
    else {
      setSelectedBook(book);
      setIsModalOpen(true);
      await fetchBookDetails(book.id); // Fetch details for the selected book
    }

  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBook(null);
    setBookDetails(null);
  };

  const startCertification = async () => {
    if (selectedBook) {
      const { id } = selectedBook;
      try {
        const {
          quiz_id,
          certificate_reference,
          no_of_question,
          answer_timeslot_insecond,
        } = await QuestionService.StartCert(id);
        navigate(`/startcert`, {
          state: {
            quiz_id,
            certificate_reference,
            no_of_question,
            answer_timeslot_insecond,
            bookname,
          },
        });
      } catch (error) {
        console.error(`${ConstantStrings.ERROR_DURING_CERTIFICATION}:`, error);
      }
    }
  };

  return (
    <div className={styles.booksContainer}>
      {
        sourceKey.query && activeButton=== null ? (
          // If there's a search query, display search results
          <p className={styles.line}>
            Search <strong>results</strong> for book {sourceKey.query}
          </p>
        ) : activeButton === "all" ? (
          // If "All" button is active, display Top Picks
          <p className={styles.line}>
            Our <strong>Top Picks</strong> for Self Reading, Learning, and getting certified
          </p>
        ) : activeButton === "bookmarked" ? (
          // If "Bookmarked" button is active, display Favorite books
          <p className={styles.line}>
            Listing of your's <strong>Favourite</strong> books
          </p>
        ) : null
      }

      <div>
        {/* "All" Button */}
        <button
          onClick={() => {
            setActiveButton("all"); // Set "all" as active
            fetchBooks("", false); // Fetch all books
          }}
          className={`${styles.bubble_button} ${activeButton === "all" ? styles.active : ""}`}
        >
          All
        </button>

        {/* "Bookmarked" Button */}
        <button
          onClick={() => {
            setActiveButton("bookmarked"); // Set "bookmarked" as active
            fetchBooks("", true); // Fetch bookmarked books
          }}
          className={`${styles.bubble_button} ${activeButton === "bookmarked" ? styles.active : ""}`}
        >
          Bookmarked
        </button>
      </div>
      {
        loading ? ( // Display loading indicator if data is being fetched
          <div className={styles.loadingContainer}>
            <div className={styles.loader}></div>
          </div>
        ) : (
          <div className={styles.bookGrid}>
            {Array.isArray(books) && books.length > 0
              ? books.map((book, index) => (
                <div key={index} className={styles.bookCard}>
                  <div className={styles.IconContainer}>
                    <FontAwesomeIcon
                      icon={faBookmark}
                      className={styles.cartIcon}
                      style={{ color: book.is_favourite ? "#008037" : "#545454" }} // Toggle color based on favourite_id
                      onClick={async () => await handleFavoriteToggle(book)}
                    />
                  </div>
                  <h3>
                    {book.name.slice(0, Math.min(book.name.length, ConstantIntegers.MAXSIZE_BOOK_NAME))}
                  </h3>
                  <img
                    src={book.thumbnail}
                    alt={book.name}
                    className={styles.bookImage}
                    onClick={() => openModal(book)}
                  />
                  <div className={styles.Certified_text}>
                    {book.certificate_id ?
                      (
                        <p onClick={() => openModal(book)}>View your certificate</p>
                      ) : (
                        <p onClick={() => openModal(book)}>Get certified today</p>
                      )
                    }
                  </div>
                </div>
              ))
              : !loading 
              }
          </div>
        )
      }

      {
        isModalOpen && (
          <div className={styles.modalOverlay} onClick={closeModal}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <span className={styles.closeButton} onClick={closeModal}>
                &times;
              </span>

              {modalLoading ? ( // Show loading indicator while fetching book details
                <div className={styles.loadingContainer}>
                  <div className={styles.loader}></div>
                </div>
              ) : (
                bookDetails &&
                selectedBook && (
                  <>
                    <p className={styles.des}>{bookDetails.book.description}</p>
                    <h3 className={styles.title}>{bookDetails.book.name}</h3>
                    <img
                      src={bookDetails.book.thumbnail}
                      alt={bookDetails.book.name}
                      className={styles.modalImage}
                    />
                    <button
                      className={`${styles.certificationButton} ${!bookDetails.questions_exists
                        ? styles.disabledButton
                        : ""
                        }`}
                      onClick={startCertification}
                      disabled={!bookDetails.questions_exists}
                    >
                      Start Certification
                    </button>
                    <p>
                      <strong>Course Summary: </strong>
                      {bookDetails.summary}
                    </p>
                    <p>
                      <strong>Why Take This Course: </strong>
                      {bookDetails.why_take_course}
                    </p>
                    <p className={styles.key_takeaways}>Key Takeaways:</p>
                    <ul>
                      {bookDetails.key_learning_outcomes.map((outcome, index) => (
                        <li key={index}>{outcome}</li>
                      ))}
                    </ul>
                    <button
                      className={`${styles.certificationButton} ${!bookDetails.questions_exists
                        ? styles.disabledButton
                        : ""
                        }`}
                      onClick={startCertification}
                      disabled={!bookDetails.questions_exists}
                    >
                      Start Certification
                    </button>
                  </>
                )
              )}
            </div>
          </div>
        )
      }
      <Link className={styles.nav_link} to="/home">
        Or Search
      </Link>
      <ToastContainer />
    </div >
  );
};

export default BookList;
