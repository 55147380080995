import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../Css/Questions.module.css";
import QuestionService from "../Services/QuestionService";
import "react-toastify/dist/ReactToastify.css";
import ConstantStrings from "../Constants/Strings";

const Questions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    quiz_id,
    no_of_question,
    answer_timeslot_insecond,
    bookname,
  } = location.state || {};
  const [pageNumber, setPageNumber] = useState(1);
  const [questionData, setQuestionData] = useState(null);
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);
  //const [result, setResult] = useState(null);
  const [timer, setTimer] = useState(answer_timeslot_insecond);
  const hasFetchedQuestions = useRef(false);
  const hasFetchedResult = useRef(false);

  useEffect(() => {
    if (!hasFetchedQuestions.current) {
      fetchQuestion();
      hasFetchedQuestions.current = true; // Mark as fetched
    }
    // if (!hasFetchedResult.current) {
    //   fetchResult();
    //   hasFetchedResult.current = true; // Mark as fetched
    // }
  }, []);

  const fetchQuestion = async () => {
    try {
      const response = await QuestionService.Question(
        quiz_id,
        pageNumber
      );
      setQuestionData(response.data);
      setSelectedAnswerId(null);
      setTimer(answer_timeslot_insecond);
    } catch (error) {
      console.error(`${ConstantStrings.ERROR_FETCHING_QUESTION}:`, error);
    }
  };

  // Function to handle answer selection
  const handleAnswerSelect = (answerId) => {
    setSelectedAnswerId(answerId);
  };
  
  // const fetchResult = async () => {
  //   try {
  //     const response = await QuestionService.GetCertificateByQuiz(quiz_id);
  //     setResult(response); // Store selected certificate details
  //   } catch (error) {
  //     console.error(`${ConstantStrings.ERROR_FETCHING_CERTIFICATE_DETAIL}:`, error);
  //   }
  // };
  // Function to submit answer and fetch the next question
  const submitAnswerAndFetchNext = async () => {
    try {
      const response = await QuestionService.GetAnswers(
        quiz_id,
        questionData.question_id,
        selectedAnswerId,
        pageNumber + 1
      );
      setQuestionData(response);
      setSelectedAnswerId(null);
      setTimer(answer_timeslot_insecond);


      if (pageNumber >= no_of_question) {
        const response = await QuestionService.GetCertificateByQuiz(quiz_id);
        if (!response || response.certificate_reference == null) {
          navigate("/result");
        }
        else {
          navigate("/mycert", { state: { quiz_id } });
        }
      } else {
        setPageNumber((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error(
        `${ConstantStrings.ERROR_SUBMITING_ANSWER}:`, error
      );
    }
  };

  useEffect(() => {
    if (timer === 0) {
      submitAnswerAndFetchNext();
    }

    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timer]);

  if (!questionData) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loader}></div>{" "}
        {/* You can customize the loader here */}
      </div>
    );
  }

  return (
    <div className={styles.questions_container}>
      <h2 className={styles.quiz_line}>Quiz in Progress</h2>
      <p className={styles.line}>
        Let’s test your knowledge and grasp from the book{" "}
        <strong>{bookname}</strong>
      </p>
      <div className={styles.question_content}>
        <h1 className={styles.question_title}>
          Question {pageNumber} of {no_of_question}
        </h1>
        <p className={styles.question_text}>
          <strong>Scenario: </strong>
          {questionData.scenario}
        </p>
        <p className={styles.question_text}>
          <strong>Question: </strong>
          {questionData.question}
        </p>
        <div className={styles.answers_container}>
          {questionData.answer_options.map((option) => (
            <div key={option.answer_id} className={styles.answer_option}>
              <input
                type="radio"
                id={option.answer_id}
                name="answer"
                checked={selectedAnswerId === option.answer_id}
                onChange={() => handleAnswerSelect(option.answer_id)}
              />
              <label htmlFor={option.answer_id}>{option.answer}</label>
            </div>
          ))}
        </div>

        <div className="timer">
          <p>Time remaining: {timer} seconds</p>
        </div>

        <button
          className={styles.next_button}
          onClick={submitAnswerAndFetchNext}
          disabled={!selectedAnswerId}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Questions;
